/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  background: rgba(0, 0, 0, 0.1);
}

/* UV */
.uv-chip {
  padding: 5px;
  box-sizing: border-box;
  margin: 2.5px 0;
  display: inline-flex;
  text-transform: uppercase;
  font-size: 11px;
  letter-spacing: 1px;
  font-weight: 100;
  border-radius: 2px;
}

.green {
  background: green;
  color: white;
}
.gold {
  background: gold;
  color: white;
}
.orange {
  background: orange;
  color: white;
}
.red {
  background: red;
  color: white;
}
.purple {
  background: purple;
  color: white;
}
